import graphqlClient from '@topconagriculture/graphql-client/build/client/clientConfiguration';
import { GRAPHQL_CLIENTS_NAMES, GRAPHQL_BASE_URLS } from '../config/constants';

const gqlInstance = graphqlClient;

gqlInstance.setUpClients([
  { name: GRAPHQL_CLIENTS_NAMES.graphqlGateway, url: GRAPHQL_BASE_URLS.graphqlGateway },
]);

export default gqlInstance;
