import { HOME_CARDS } from './cardsContents';

export const URLS = {
  resources: process.env.GATSBY_RESOURCES_URL,
  signup: process.env.GATSBY_SIGNUP_URL,
  fallbackLogin: process.env.GATSBY_FALLBACK_LOGIN_URL,
  developerPortal: process.env.GATSBY_DEVELOPER_PORTAL_URL,
  helpPortal: process.env.GATSBY_HELP_PORTAL_URL,
  privacyUrl: process.env.GATSBY_PRIVACY_DOC_URL,
  profileManagerUrl: process.env.GATSBY_PROFILE_MANAGER_URL,
  topconHomeUrl: process.env.GATSBY_TOPCON_HOME,
  landingSiteUrl: process.env.GATSBY_LANDING_SITE_URL,
};

export const AUTH0 = {
  clientId: process.env.GATSBY_AUTH0_CLIENT_ID,
  domain: process.env.GATSBY_AUTH0_DOMAIN,
  audience: process.env.GATSBY_AUTH0_AUDIENCE,
  scope: process.env.GATSBY_AUTH0_SCOPE,
  environment: process.env.GATSBY_ENVIRONMENT,
  redirect_uri: process.env.GATSBY_AUTH0_REDIRECT_URI,
};

export const GRAPHQL_CLIENTS_NAMES = {
  graphqlGateway: 'graphqlClient',
};

export const GRAPHQL_BASE_URLS = {
  graphqlGateway: process.env.GATSBY_GRAPHQL_GATEWAY_URL,
};

export const API_BASE_URL = process.env.GATSBY_SUBSCRIPTIONS_BASE_URL;

export const ENV_VARS = {
  recaptchaKey: process.env.GATSBY_RECAPTCHA_KEY,
};

export const ENTITIES = {
  dealer: 'dealer',
  farmer: 'farmer',
  agronomist: 'agronomist',
};

export const NAV_ITEMS = {
  home: { id: 'home', labelKey: 'app:nav:home', linkTo: '/' },
  login: { id: 'login', labelKey: 'app:nav:login', linkTo: '/login' },
  about: { id: 'about', labelKey: 'app:nav:about', linkTo: '/about/' },
  contactUs: { id: 'contact-us', labelKey: 'app:nav:contact_us', linkTo: '/contact-us/' },
  ...HOME_CARDS,
};

export const LANGUAGES = [
  { labelKey: 'da', value: 'da' },
  { labelKey: 'de', value: 'de' },
  { labelKey: 'en', value: 'en' },
  { labelKey: 'es', value: 'es' },
  { labelKey: 'fr', value: 'fr' },
  { labelKey: 'it', value: 'it' },
  { labelKey: 'ja', value: 'ja' },
  { labelKey: 'nl', value: 'nl' },
  { labelKey: 'pt', value: 'pt' },
  { labelKey: 'ru', value: 'ru' },
];

export const SECTIONS_IDS = {
  dealer: 'dealer',
  agronomist: 'agronomist',
  farmer: 'farmer',
  opinions: 'opinions',
  partners: 'partners',
};

export const contactReasons = [
  { labelKey: 'app:contact-us:form:reason1', value: 'COMMERCIAL' },
  { labelKey: 'app:contact-us:form:reason2', value: 'TECHNICAL' },
  { labelKey: 'app:contact-us:form:reason3', value: 'SUPPORT' },
  { labelKey: 'app:contact-us:form:reason4', value: 'OTHER' },
];

export const PAGE_OG_IMAGES = {
  about: 'og-aboutus.jpg',
  agronomist: 'og-agronomist.jpg',
  'contact-us': 'og-contactus.jpg',
  dealer: 'og-dealer.jpg',
  farmer: 'og-farmer.jpg',
  home: 'og-landing.jpg',
};
