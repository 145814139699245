/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import './src/assets/styles/import.css';
import 'intersection-observer';
export { wrapRootElement } from './src/auth/wrap-root-element';
