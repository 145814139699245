export const HOME_CARDS = {
  farmer: { id: 'farmer', labelKey: 'app:nav:farmer', linkTo: '/farmer/', image: 'farmer-personas.png' },
  dealer: { id: 'dealer', labelKey: 'app:nav:dealer', linkTo: '/dealer/', image: 'dealer-personas.png' },
  agronomist: {
    id: 'agronomist',
    labelKey: 'app:nav:agronomist',
    linkTo: '/agronomist/',
    image: 'agronomist-personas.png',
  },
};
export const professionistItems = Object.values(HOME_CARDS);

export const DEALER_CARDS = [
  { image: 'cards/fields-overview.png' },
  { image: 'cards/fields-tasks.png' },
  { image: 'cards/dashboard.png' },
];

export const FARMER_CARDS = [
  { image: 'cards/fields-overview.png' },
  { image: 'cards/fields-tasks.png' },
  { image: 'cards/dashboard.png' },
];

export const AGRONOMIST_CARDS = [
  { image: 'cards/fields-overview.png' },
  { image: 'cards/fields-tasks.png' },
  { image: 'cards/dashboard.png' },
];
